import React, { useState } from 'react';
import isEmpty from 'lodash-es/isEmpty';
import Modal from 'react-modal';
import Slider from 'react-slick';

import StarLogo from '../../assets/images/breath-comment-full-star.svg';
import NextArrow from '../../assets/images/breath-comment-slider-next-btn.svg';
import PrevArrow from '../../assets/images/breath-comment-slider-prev-btn.svg';
import StoreLogo from '../../assets/images/breath-comment-store-logo.svg';
import CloseLogo from '../../assets/images/card-close.svg';

const starRender = rating => {
  let starWrap = [];
  for (let i = 0; i < rating; i++) {
    starWrap.push(<StarLogo className="comments-slider__star" key={`${i}s`} />);
  }
  return starWrap;
};

const CommentBlock = ({ headerContent, cards }) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [currentCard, setCurrentCard] = useState({});
  const allCards = cards;

  const modalStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.9)',
      zIndex: 999,
    },
    content: {
      position: 'relative',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      display: 'flex',
      border: '0px',
      transform: 'translate(-50%, -50%)',
      overflow: 'auto',
      background: 'none',
      borderRadius: 'none',
      padding: '20px',
      maxWidth: '678px',
    },
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleCardClick = item => {
    setIsOpen(true);
    setCurrentCard(item);
  };

  const PrevBtn = props => {
    const { className, style, onClick } = props;

    return (
      <button
        className={`${className} comments-slider__btn`}
        style={{ ...style, display: 'block' }}
        onClick={onClick}
      >
        <PrevArrow className="comments-slider__btn-arrow" />
      </button>
    );
  };

  const NextBtn = props => {
    const { className, style, onClick } = props;

    return (
      <button
        className={`${className} comments-slider__btn`}
        style={{ ...style, display: 'block' }}
        onClick={onClick}
      >
        <NextArrow className="comments-slider__btn-arrow" />
      </button>
    );
  };

  const settings = {
    className: 'comments-slider',
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: <PrevBtn />,
    nextArrow: <NextBtn />,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
    ],
  };

  return (
    <section className="comments">
      <div className="container">
        <div className="comments__inner">
          <h2 className="comments__title">{headerContent.feedbackHeaderTitle}</h2>
          <div
            className="comments__description"
            dangerouslySetInnerHTML={{
              __html: headerContent.feedbackHeaderDescription.childMarkdownRemark.html,
            }}
          />
          <Slider {...settings}>
            {cards.map(item => (
              <div
                className="comments-slider__item"
                key={item.id}
                onClick={() => handleCardClick(item)}
              >
                <StoreLogo className="comments-slider__store-logo" />
                <div className="comments-slider__item-content">
                  <p className="comments-slider__item-author">{item.feedbackAuthor}</p>
                  <time className="comments-slider__item-date">{item.feedbackDate}</time>
                  <div className="comments-slider__item-rating">
                    {starRender(item.feedbackRating).map(item => item)}
                  </div>
                  <p className="comments-slider__item-text">
                    {item.feedbackText.feedbackText}
                  </p>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Comment Card Modal"
        style={modalStyles}
        closeTimeoutMS={300}
      >
        {!isEmpty(currentCard) && (
          <div className="card-modal">
            <button className="card-modal__close-btn" onClick={closeModal}>
              <CloseLogo className="card-modal__close-icon" />
            </button>
            <StoreLogo className="card-modal__store-logo" />
            <div className="card-modal__inner">
              <p className="card-modal__author">{currentCard.feedbackAuthor}</p>
              <time className="card-modal__date">{currentCard.feedbackDate}</time>
              <div className="card-modal__item-rating">
                {starRender(currentCard.feedbackRating).map(item => item)}
              </div>
              <p className="card-modal__text">{currentCard.feedbackText.feedbackText}</p>
            </div>
          </div>
        )}
      </Modal>
    </section>
  );
};

export default CommentBlock;
