import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import CommentBlock from '../components/CommentBlock';
import Footer from '../components/Footer';
import Header from '../components/Header';
import InstaBlock from '../components/InstaBlock';
import MainSlider from '../components/MainSlider';
import ResultBlock from '../components/ResultBlock';
import SEO from '../components/Seo';
import StoresBanner from '../components/StoresBanner';

import '../assets/styles/core.scss';

const IndexPage = () => {
  const {
    metaData,
    pageHeaderContent,
    practiceHeader,
    practiceCards,
    resultHeader,
    resultItems,
    commentsHeader,
    commentCards,
    footerEmail,
  } = useStaticQuery(graphql`
    {
      metaData: contentfulPageMetaData(
        id: { eq: "274feef4-3e07-5808-8fb0-adea7a2bf226" }
      ) {
        metaDescription {
          metaDescription
        }
        metaTitle
      }
      pageHeaderContent: contentfulHeaderContent(
        id: { eq: "7f30d008-2270-5db4-a72b-64b1f9f07bab" }
      ) {
        headerTitle
        headerDescription
        headerLargeDesktopBg {
          fluid(quality: 100) {
            ...GatsbyContentfulFluid
            ...GatsbyContentfulFluid_withWebp
          }
        }
        headerDesktopBg {
          fluid(quality: 100, maxWidth: 1650) {
            ...GatsbyContentfulFluid
            ...GatsbyContentfulFluid_withWebp
          }
        }
        headerTabletbg {
          fluid(quality: 100, maxWidth: 1024) {
            ...GatsbyContentfulFluid
            ...GatsbyContentfulFluid_withWebp
          }
        }
        headerMobileBg {
          fluid(quality: 100, maxWidth: 500) {
            ...GatsbyContentfulFluid
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
      practiceHeader: contentfulPracticeBlockHeader(
        id: { eq: "f7a41ff8-a389-540b-9d02-067e3ca5faef" }
      ) {
        practiceTitle
        practiceDescription {
          practiceDescription
        }
      }
      practiceCards: allContentfulPracticeSliderCard {
        edges {
          node {
            id
            cardDescription {
              cardDescription
            }
            cardTitle
            cardSubtitle
            cardLineColor
            cardImage {
              fluid(quality: 100, maxHeight: 320, maxWidth: 400) {
                ...GatsbyContentfulFluid
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
        }
      }
      resultHeader: contentfulFunctionalityBlockHeader(
        id: { eq: "e66a5a3b-c2a0-5c70-b82c-664741b15913" }
      ) {
        functionalityHeaderTitle
        functionalityHeaderDescription
      }
      resultItems: allContentfulFunctionalityBlockItem(
        filter: { spaceId: { eq: "cu1x96hcw021" } }
      ) {
        nodes {
          id
          functionalityItemTitle
          functionalityItemText {
            childMarkdownRemark {
              html
            }
          }
          functionalityItemImage {
            fluid(quality: 100, maxWidth: 288, maxHeight: 578) {
              ...GatsbyContentfulFluid
              ...GatsbyContentfulFluid_withWebp
            }
          }
          functionalityItemVideo {
            file {
              url
            }
          }
        }
      }
      commentsHeader: contentfulFeedbackBlockHeader(
        id: { eq: "d1eafa22-6e8c-5781-a967-e8404b30fd0a" }
      ) {
        feedbackHeaderTitle
        feedbackHeaderDescription {
          childMarkdownRemark {
            html
          }
        }
      }
      commentCards: allContentfulFeedbackSliderItem {
        nodes {
          id
          feedbackAuthor
          feedbackDate(fromNow: true)
          feedbackRating
          feedbackText {
            feedbackText
          }
        }
      }
      footerEmail: contentfulFooterEmail(
        id: { eq: "5d3e8fea-86fb-566c-b77e-6222bfb82744" }
      ) {
        email
      }
    }
  `);

  return (
    <>
      <SEO
        title={metaData.metaTitle}
        description={metaData.metaDescription.metaDescription}
      />
      <Header headerContent={pageHeaderContent} />
      <MainSlider sliderCards={practiceCards.edges} textContent={practiceHeader} />
      <ResultBlock headerContent={resultHeader} resultItems={resultItems.nodes} />
      <CommentBlock headerContent={commentsHeader} cards={commentCards.nodes} />
      <InstaBlock />
      <StoresBanner />
      <Footer footerEmail={footerEmail.email} />
    </>
  );
};

export default IndexPage;
