import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';

import telFrame from '../../assets/images/breath-video-frame.png';
import CookieBar from '../CookieBar';
import StoresWrap from '../UIComponents/StoresWrap';

const ResultBlock = ({ headerContent, resultItems }) => {
  return (
    <section className="result">
      <div className="container">
        <div className="result__inner">
          <h2 className="result__title">{headerContent.functionalityHeaderTitle}</h2>
          <span className="result__subtitle">
            {headerContent.functionalityHeaderDescription}
          </span>
          <ul className="result__list">
            {resultItems.map(item => (
              <li
                className="result__item"
                key={item.id}
                data-sal="slide-up"
                data-sal-duration="1500"
                data-sal-delay="100"
                data-sal-easing="ease-out-back"
              >
                <div className="result__description-wrap">
                  <h3 className="result__item-title">{item.functionalityItemTitle}</h3>
                  <div
                    className="result__item-text"
                    dangerouslySetInnerHTML={{
                      __html: item.functionalityItemText.childMarkdownRemark.html,
                    }}
                  />
                </div>
                <div className="result__video-wrap">
                  {item.functionalityItemVideo.file.url ? (
                    <>
                      <img
                        src={telFrame}
                        className="result__video-frame"
                        alt="tel-frame"
                      />
                      <video
                        src={item.functionalityItemVideo.file.url}
                        className="result__video"
                        loop
                        autoPlay
                        playsInline
                        muted
                      />
                    </>
                  ) : (
                    <Img
                      fluid={item.functionalityItemImage.fluid}
                      className="result__image"
                    />
                  )}
                </div>
              </li>
            ))}
          </ul>
          <StoresWrap />
        </div>
      </div>
      <CookieBar />
    </section>
  );
};

ResultBlock.propTypes = {
  headerContent: PropTypes.shape({
    functionalityHeaderTitle: PropTypes.string,
    functionalityHeaderDescription: PropTypes.string,
  }),
  resultItems: PropTypes.arrayOf(PropTypes.shape({})),
};

export default ResultBlock;
