import React from 'react';
import CookieBanner from 'react-cookie-banner';

const MyCustomCookieBanner = ({ onAccept }) => {
  return (
    <div className="cookie-bar">
      <div className="container">
        <div className="cookie-bar__inner">
          <div className="cookie-bar__title">This website uses cookies</div>
          <p className="cookie-bar__message">
            This site uses cookies to provide you with a great user experience. By using
            Breath Method, you accept our use of cookies.
          </p>
          <button className="cookie-bar__btn" onClick={onAccept}>
            Accept cookies
          </button>
        </div>
      </div>
    </div>
  );
};

const CookieBar = () => {
  return (
    <CookieBanner dismissOnScroll={false} cookie="user-accepted-cookie">
      {onAccept => <MyCustomCookieBanner onAccept={onAccept} />}
    </CookieBanner>
  );
};

export default CookieBar;
