import React from 'react';
import { Link } from 'gatsby';

import FooterLogo from '../../assets/images/breath-footer-logo.svg';

const Footer = ({ footerEmail }) => (
  <footer className="footer">
    <div className="container">
      <div className="footer__inner">
        <FooterLogo className="footer__logo" />
        <div className="footer__links-wrap">
          <a href={`mailto:${footerEmail}`} className="footer__mail-link">
            {footerEmail}
          </a>
          <Link to="/privacy-policy" className="footer__privacy-link">
            Read our privacy policy
          </Link>
          <p className="footer__text">
            © {new Date().getFullYear()} Trade Square Pte Ltd. All rights reserved.
          </p>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
