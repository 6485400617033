import React from 'react';

import AppStore from '../../../assets/images/breath-app-store.svg';
import GooglePlay from '../../../assets/images/breath-google-play.svg';

const StoresWrap = () => (
  <div className="stores">
    <a
      href="https://apps.apple.com/gb/app/breath-method/id1515715334"
      target="_blank"
      className="stores__link"
    >
      <AppStore className="stores__appstore-logo" />
    </a>
    <a
      href="https://play.google.com/store/apps/details?id=com.thebreathmethod"
      target="_blank"
      className="stores__link"
    >
      <GooglePlay className="stores__google-logo" />
    </a>
  </div>
);

export default StoresWrap;
