import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import StoresWrap from '../UIComponents/StoresWrap';

const StoresBanner = () => {
  const { headerContent } = useStaticQuery(graphql`
    {
      headerContent: contentfulBannerBlockTitle {
        bannerTitle
      }
    }
  `);
  return (
    <section className="banner">
      <div className="container">
        <div className="banner__inner">
          <h2 className="banner__title">{headerContent.bannerTitle}</h2>
          <StoresWrap />
        </div>
      </div>
    </section>
  );
};

export default StoresBanner;
