import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import isEmpty from 'lodash-es/isEmpty';
import Modal from 'react-modal';

import NextArrow from '../../assets/images/breath-comment-slider-next-btn.svg';
import PrevArrow from '../../assets/images/breath-comment-slider-prev-btn.svg';
import InstaLogo from '../../assets/images/breath-insta-icon.svg';
import CloseBtn from '../../assets/images/breath-modal-close-btn.svg';

const InstaBlock = () => {
  const { instaFeed, instaUser } = useStaticQuery(graphql`
    {
      instaFeed: allInstaNode(limit: 10, sort: { fields: timestamp, order: DESC }) {
        nodes {
          id
          caption
          likes
          localFile {
            childImageSharp {
              fluid(maxHeight: 640, maxWidth: 600, quality: 100) {
                ...GatsbyImageSharpFluid
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      instaUser: contentfulInstagramUser(
        id: { eq: "3f629953-5fcc-5210-b94b-2cd724ccfded" }
      ) {
        instaUsername
        instaAvatar {
          file {
            url
          }
        }
      }
    }
  `);

  const [modalIsOpen, setIsOpen] = useState(false);
  const [currentPost, setCurrentPost] = useState({});
  const allPost = instaFeed.nodes;

  Modal.setAppElement(`#___gatsby`);

  const modalStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.9)',
      zIndex: 999,
    },
    content: {
      position: 'relative',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      display: 'flex',
      border: '0px',
      transform: 'translate(-50%, -50%)',
      overflow: 'auto',
      background: 'none',
      padding: '0px',
      maxWidth: '1245px',
    },
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const handlePostClick = item => {
    setIsOpen(true);
    setCurrentPost(item);
  };

  const handleNextBtnClick = () => {
    let nextIndex = allPost.findIndex(item => item.id === currentPost.id) + 1;
    if (nextIndex === allPost.length) {
      nextIndex = 0;
    }
    setCurrentPost(allPost[nextIndex]);
  };

  const handlePrevBtnClick = () => {
    let prevIndex = allPost.findIndex(item => item.id === currentPost.id) - 1;
    if (prevIndex < 0) {
      prevIndex = allPost.length - 1;
    }
    setCurrentPost(allPost[prevIndex]);
  };

  return (
    <section className="insta-block">
      <div className="container">
        <div className="insta-block__inner">
          <ul className="insta-block__post-list">
            {allPost.map(item => (
              <li
                className="insta-block__post-item"
                key={item.id}
                onClick={() => handlePostClick(item)}
              >
                <InstaLogo className="insta-block__insta-logo" />
                <div className="insta-block__bg"></div>
                <Img
                  fluid={item.localFile.childImageSharp.fluid}
                  className="insta-block__img"
                />
              </li>
            ))}
          </ul>
          <div className="insta-block__follow-wrap">
            <span className="insta-block__follow-tag">@thebreathmethod</span>
            <a
              href="https://instagram.com/thebreathmethod?igshid=fd1l8c8mdq0a"
              target="_blank"
              rel="noopener noreferrer"
              className="insta-block__follow-btn"
            >
              follow
            </a>
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Modal"
        style={modalStyles}
        closeTimeoutMS={300}
      >
        {!isEmpty(currentPost) && (
          <div className="insta-modal">
            <button className="insta-modal__close-btn" onClick={closeModal}>
              <CloseBtn className="insta-modal__close-icon" />
            </button>
            <div className="insta-modal__inner">
              <div className="insta-modal__img-wrap">
                <Img
                  fluid={currentPost.localFile.childImageSharp.fluid}
                  className="insta-modal__img"
                />
              </div>
              <div className="insta-modal__post">
                <div className="insta-modal__author">
                  <img
                    src={instaUser.instaAvatar.file.url}
                    className="insta-modal__avatar"
                    alt="insta-avatar"
                  />
                  {}
                  <span className="insta-modal__author-name">
                    {instaUser.instaUsername}
                  </span>
                </div>
                <div className="insta-modal__text-content">
                  <div className="insta-modal__title">
                    The Breath Method Instagram Post
                  </div>
                  <p className="insta-modal__caption">{currentPost.caption}</p>
                </div>
              </div>
            </div>
            <button
              className="insta-modal__btn insta-modal__btn--prev"
              onClick={handlePrevBtnClick}
            >
              <PrevArrow className="insta-modal__arrow-icon" />
            </button>
            <button
              className="insta-modal__btn insta-modal__btn--next"
              onClick={handleNextBtnClick}
            >
              <NextArrow className="insta-modal__arrow-icon" />
            </button>
          </div>
        )}
      </Modal>
    </section>
  );
};

export default InstaBlock;
